import service from '@/utils/request';

// @Tags SysTeam
// @Summary 创建SysTeam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeam true "创建SysTeam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysTeam/createSysTeam [post]
export var createSysTeam = function createSysTeam(data) {
  return service({
    url: "/sysTeam/createSysTeam",
    method: 'post',
    data: data
  });
};

// @Tags SysTeam
// @Summary 删除SysTeam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeam true "删除SysTeam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysTeam/deleteSysTeam [delete]
export var deleteSysTeam = function deleteSysTeam(data) {
  return service({
    url: "/sysTeam/deleteSysTeam",
    method: 'delete',
    data: data
  });
};

// @Tags SysTeam
// @Summary 删除SysTeam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除SysTeam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /sysTeam/deleteSysTeam [delete]
export var deleteSysTeamByIds = function deleteSysTeamByIds(data) {
  return service({
    url: "/sysTeam/deleteSysTeamByIds",
    method: 'delete',
    data: data
  });
};

// @Tags SysTeam
// @Summary 更新SysTeam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeam true "更新SysTeam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /sysTeam/updateSysTeam [put]
export var updateSysTeam = function updateSysTeam(data) {
  return service({
    url: "/sysTeam/updateSysTeam",
    method: 'put',
    data: data
  });
};

// @Tags SysTeam
// @Summary 用id查询SysTeam
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysTeam true "用id查询SysTeam"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /sysTeam/findSysTeam [get]
export var findSysTeam = function findSysTeam(params) {
  return service({
    url: "/sysTeam/findSysTeam",
    method: 'get',
    params: params
  });
};

// @Tags SysTeam
// @Summary 分页获取SysTeam列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取SysTeam列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /sysTeam/getSysTeamList [get]
export var getSysTeamList = function getSysTeamList(params) {
  return service({
    url: "/sysTeam/getSysTeamList",
    method: 'get',
    params: params
  });
};